import React, { useState } from 'react'
import { ActionButton, DragAndDrop, VStack } from '@revolut/ui-kit'
import {
  JobPostingInterface,
  JobPostingSectionInterface,
} from '@src/interfaces/jobPosting'
import { DescriptionSection } from '@src/features/JobPostingFlow/Details/DescriptionSection'
import { move } from '@src/utils/move'
import { useLapeContext } from '@src/features/Form/LapeForm'
import { generateRandomId } from '@src/utils/numbers'

export const DescriptionSections = () => {
  const { values, errors } = useLapeContext<JobPostingInterface>()
  const sections = values.sections
  const currentSections = sections || []

  const [activeIdx, setActiveIdx] = useState<number | null>(null)
  const activeSectionIndex =
    activeIdx !== null
      ? currentSections.findIndex(({ sortId }) => sortId === activeIdx)
      : -1
  const activeSection =
    activeSectionIndex >= 0 ? currentSections[activeSectionIndex] : undefined
  const activeSectionErrors = errors?.sections?.[activeSectionIndex]

  const handleAdd = () => {
    const newSections = [
      ...currentSections,
      {
        content: '',
        title: '',
        sortId: generateRandomId(),
      },
    ]
    values.sections = newSections
  }

  const handleDelete = (sectionSortId: number) => {
    if (sectionSortId !== null) {
      const sectionIndex = currentSections.findIndex(
        ({ sortId }) => sectionSortId !== sortId,
      )

      const newSections = currentSections.filter(({ sortId }) => sectionSortId !== sortId)
      values.sections = newSections

      if (errors.sections) {
        errors.sections = errors.sections.filter(
          (_, errorIndex) => errorIndex !== sectionIndex,
        )
      }
    }
  }

  const handleOrderChange = (startIndex: number, endIndex: number) => {
    if (startIndex !== endIndex) {
      values.sections = move(currentSections, startIndex, endIndex)
      if (errors.sections) {
        errors.sections = move(errors.sections, startIndex, endIndex)
      }
    }
    setActiveIdx(null)
  }

  const handleChange = (section: JobPostingSectionInterface, sectionIndex: number) => {
    if (values.sections) {
      values.sections[sectionIndex] = section
    }
  }

  return (
    <VStack gap="s-16">
      <DragAndDrop.Provider
        onDragStart={event => {
          setActiveIdx(Number(event.active.id))
        }}
        onDragEnd={event => {
          if (event.over) {
            handleOrderChange(
              event?.active?.data?.current?.sortable.index ?? 0,
              event?.over?.data?.current?.sortable.index ?? 0,
            )
          }
        }}
        onDragCancel={() => {
          setActiveIdx(null)
        }}
      >
        <DragAndDrop.Sortable
          id="sortable"
          items={currentSections.map(section => section.sortId)}
        >
          {sortable => {
            const sectionIndex = currentSections.findIndex(
              section => section.sortId === sortable.id,
            )
            const section = currentSections[sectionIndex]
            const sectionErrors = errors?.sections?.[sectionIndex]
            return (
              <DescriptionSection
                disable={currentSections.length === 1}
                section={section}
                sectionIndex={sectionIndex}
                sortable={sortable}
                titleError={sectionErrors?.title}
                onChange={newSection => handleChange(newSection, sectionIndex)}
                onDelete={() => handleDelete(section.sortId)}
              />
            )
          }}
        </DragAndDrop.Sortable>
        <DragAndDrop.DragOverlay>
          {activeIdx && activeSection && (
            <DescriptionSection
              disable={false}
              section={activeSection}
              sectionIndex={activeSectionIndex}
              titleError={activeSectionErrors?.title}
              onChange={() => {}}
              onDelete={() => {}}
            />
          )}
        </DragAndDrop.DragOverlay>
      </DragAndDrop.Provider>
      <ActionButton useIcon="Plus" onClick={() => handleAdd()}>
        Add section
      </ActionButton>
    </VStack>
  )
}
