import React from 'react'
import {
  ActionButton,
  DragAndDrop,
  Flex,
  HStack,
  IconButton,
  Input,
  VStack,
  Widget,
} from '@revolut/ui-kit'
import { JobPostingSectionInterface } from '@src/interfaces/jobPosting'
import HTMLEditor from '@src/components/HTMLEditor/HTMLEditor'

interface DescriptionSectionProps {
  disable: boolean
  section: JobPostingSectionInterface
  sectionIndex: number
  sortable?: DragAndDrop.DefaultSortableItemState<{}>
  titleError?: string
  onChange: (section: JobPostingSectionInterface) => void
  onDelete: VoidFunction
}

export const DescriptionSection = ({
  disable,
  section,
  sectionIndex,
  sortable,
  titleError,
  onChange,
  onDelete,
}: DescriptionSectionProps) => {
  return (
    <Widget
      aria-label={`Description widget ${sectionIndex}`}
      p="s-16"
      ref={sortable?.setNodeRef}
      style={
        sortable
          ? {
              transform: sortable.transform
                ? `translate3d(${sortable.transform.x}px, ${sortable.transform.y}px, 0)`
                : undefined,
              transition: sortable.transition || 'none',
              opacity: sortable.isDragging ? 0 : undefined,
            }
          : undefined
      }
    >
      <Flex alignItems="center" gap="s-8" mb="s-16" justifyContent="space-between">
        {disable ? (
          <IconButton useIcon="Drag" disabled />
        ) : (
          <IconButton
            useIcon="Drag"
            ref={sortable?.setNodeRef}
            {...sortable?.attributes}
            {...sortable?.listeners}
          />
        )}
        <HStack gap="s-8" align="center">
          <ActionButton
            aria-label={`Delete ${section.title}`}
            disabled={disable}
            iconOnly
            size="xs"
            useIcon="Delete"
            onClick={() => {
              onDelete()
            }}
          />
        </HStack>
      </Flex>
      <VStack gap="s-16" width="100%">
        <Input
          required
          label="Section title"
          value={section.title}
          onChange={event => {
            onChange({
              ...section,
              title: event.currentTarget.value,
            })
          }}
          name={`sections.${sectionIndex}.title`}
          invalid={!!titleError}
          errorMessage={titleError}
        />
        <HTMLEditor
          addMarginToParagraphs
          height={80}
          data-name={`sections.${sectionIndex}.content`}
          value={section.content}
          onChange={newContent => {
            if (newContent) {
              onChange({
                ...section,
                content: newContent,
              })
            }
          }}
        />
      </VStack>
    </Widget>
  )
}
